import { getCookie }           from "@/utils/cookies";
import { scrollElemToTop }     from "@/utils/dom";
import { forwardToUrl }        from "@/utils/forward-to-url";
import { executeOnWindowLoad } from "@/utils/withWindowLoad";

export function initFwdLinks() {
    const fwdLinks = document.querySelectorAll<HTMLElement>('.fwd[data-fwd]');

    fwdLinks.forEach(fwd => {
        fwd.addEventListener('click', (event) => {
            const cartExitModal = document.getElementById('cart-exit-modal');
            const shouldSkipModal = getCookie('skipCartSaveModal');
            const inNewTab = (/^(true|1)$/i).test(fwd.dataset.new || '');
            
            if (!fwd.dataset.fwd || (!inNewTab && cartExitModal && shouldSkipModal === null)) {
                return true;
            }
            const forwardUrl = fwd.dataset.fwd;
            const hasProtocol = forwardUrl.startsWith('https://') || forwardUrl.startsWith('tel:');
            const url        = new URL(hasProtocol ? forwardUrl : window.baseUrl + forwardUrl);

            const urlHash = url.hash;
            const scrollElementId = urlHash.substring(1);
            const scrollElement = document.getElementById(scrollElementId);

            event.preventDefault();
            if (scrollElement) {
                scrollElemToTop(scrollElement, -70);
            } else {
                forwardToUrl(
                    url.href,
                    inNewTab,
                );
            }
            return false; 
        });
    });
}

executeOnWindowLoad(() => {
    initFwdLinks();
});
